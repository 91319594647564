import EventHub from "@/plugins/EventHub";

export default {
    state: {
        tokenClient: null,
        accessToken: null,
        gisInited: false,
        data: null,
        user: null,
        module: null,
    },
    actions: {
        syncDrive: ({ commit, dispatch }, module) => {
            window.gapi.load("picker");
            const tokenClient = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.GOOGLE_CLIENT_ID,
                scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive",
                ux_mode: "popup",
            });
            commit("setTokenClient", tokenClient);
            commit("setGisInited", true);
            commit("setModule", module);
            return dispatch("createPicker");
        },
        createPicker: ({ state, commit, dispatch }) => {
            state.tokenClient.callback = async (response) => {
                if (response.error !== undefined) {
                    throw response;
                }
                commit("setAccessToken", response.access_token);
                await dispatch("getUserInfo", response.access_token);
                return dispatch("showPicker");
            };

            if (state.accessToken === null) {
                state.tokenClient.requestAccessToken({ prompt: "consent" });
            } else {
                state.tokenClient.requestAccessToken({ prompt: "" });
            }
        },
        getUserInfo: ({ commit }, token) => {
            return axios
                .get(
                    `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`
                )
                .then((res) => {
                    commit("setUser", res);
                });
        },
        showPicker: ({ state, dispatch }) => {
            const picker = new window.google.picker.PickerBuilder()
                .addView(window.google.picker.ViewId.DOCS)
                .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
                .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
                .setOAuthToken(state.accessToken)
                .setCallback((data) => {
                    return dispatch("pickerCallback", data);
                })
                .build();
            picker.setVisible(true);
        },
        pickerCallback: ({ state, commit, dispatch }, data) => {
            if (data.action === "picked") {
                const documents = data.docs.map((item) => {
                    return {
                        document_name: item?.name,
                        document_link: item?.url,
                        embed_link: item?.embedUrl,
                        document_id: item?.id,
                        service_id: item?.serviceId,
                        mime_type: item?.mimeType,
                        doc_type: item?.type,
                        icon_url: item?.iconUrl,
                        organization_display_name: item.organizationDisplayName,
                    };
                });
                const finalData = {
                    drive_type: "google_drive",
                    module_name: state.module,
                    email: state.user.email,
                    sub: state.user.sub,
                    name: state.user.name,
                    picture: state.user.picture,
                    email_verified: state.user.email_verified,
                    hd: state.user.hd,
                    documents: documents,
                };
                dispatch("documents/create", finalData, { root: true }).then(
                    () => {
                        EventHub.$emit(`synced/${state.module}`);
                        commit("reset");
                    }
                );
                return finalData;
            }
            return data;
        },
    },

    mutations: {
        setTokenClient: (state, data) => {
            state.tokenClient = data;
        },
        setAccessToken: (state, token) => {
            state.accessToken = token;
        },
        setGisInited: (state, value) => {
            state.gisInited = value;
        },
        setUser: (state, data) => {
            state.user = data;
        },
        setModule: (state, module) => {
            state.module = module;
        },

        reset: (state) => {
            state.tokenClient = null;
            state.accessToken = null;
            state.gisInited = false;
            state.data = null;
            state.user = null;
            state.module = null;
        },
    },
};
