import {
    $defaultParams,
    $setParams,
    getMessageFromError,
    $notify,
} from "@/plugins/Helpers";

export default {
    namespaced: true,
    state: {
        reports: [],
        report: {},
    },
    actions: {
        fetchReports: ({ rootState, commit }, params = {}) => {
            params = { ...$defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/reports/all/${$setParams(params)}`
                )
                .then(async (response) => {
                    await commit("setReports", response.results);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        retriveReport: ({ rootState, commit }, id) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/reports/retrive/${id}/`
                )
                .then(async (response) => {
                    await commit("setReport", response);
                    return response;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchReportConfig: ({ rootState }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/reports/config/`
                )
                .then((res) => {
                    return res;
                });
        },
        generate: ({ rootState }, { payload, action }) => {
            // action: generate/save
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/reports/data/?action=${action}`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch(() => {
                    $notify.error("Unable to bind data", "error");
                });
        },
        update: ({ rootState }, { payload, action }) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/reports/update/${payload.id}/?action=${action}`,
                    payload
                )
                .then((res) => {
                    return res;
                });
        },
        moveToDashdoard: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/reports/move-dashboard/${payload.report_id}/`
                )
                .then((res) => {
                    return res;
                });
        },
    },
    mutations: {
        setReports: (state, payload) => {
            state.reports = payload;
        },
        clearReports: (state) => {
            state.reports = [];
        },
        setReport: (state, payload) => {
            state.report = payload;
        },
        clearReport: (state) => {
            state.report = {};
        },
        reset: (state) => {
            state.reports = [];
            state.report = {};
        },
    },
};
