import { getMessageFromError, $setParams } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};

export default {
    namespaced: true,
    state: {
        activities: [],
        activity: {},
    },
    actions: {
        fetchActivities: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/program_activities/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setActivities", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createActivity: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/program_activities/create/`,
                    payload
                )
                .then((res) => {
                    return res;
                });
            // .catch((error) => {
            //     getMessageFromError(error);
            // });
        },
        updateActivity: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/program_activities/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadProgram: ({ rootState })=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/program_activities/download/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        uploadProgramFile: ({ rootState },payload)=>{
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/program_activities/upload/`,payload
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        }
    },
    mutations: {
        setActivities: (state, payload) => {
            state.activities = payload;
        },
        clearActivities: (state) => {
            state.activities = [];
        },
        setActivity: (state, payload) => {
            state.activity = payload;
        },
        clearActivity: (state) => {
            state.activity = {};
        },
        reset: (state) => {
            state.activities = [];
            state.activity = {};
        },
    },
};
