import axios from "axios"

export default {
    namespaced: true,
    state: {
        uesrOrNotUser:{},
        authorityLabel:{},
        paidOrFreeUser:{},
        teamType:{},
    },
    actions:{
        fetchUsrOrNotuser: ({ rootState, commit }) => {
            return axios.get(`/organization/${rootState.auth.organization.organization}/chart/user/vs/notuser/`)
            .then((res) => {
                commit("setUserOrNotuserInfo",res);
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchAuthorityLabel: ({rootState,commit})=>{
            return axios.get(`/organization/${rootState.auth.organization.organization}/chart/authority/label/`)
            .then((res) => {
                commit("setAuthorityLabel",res);
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchFreeOrPaiduser: ({rootState,commit})=>{
            return axios.get(`/organization/${rootState.auth.organization.organization}/chart/paiduser/vs/freeuser/`)
            .then((res) => {
                commit("setPaidOrFreeuser",res);
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchTeamType: ({rootState,commit})=>{
            return axios.get(`/organization/${rootState.auth.organization.organization}/chart/team/vs/type/`)
            .then((res) => {
                commit("setTeamType",res);
                return res;
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },
    mutations:{
        setUserOrNotuserInfo:(state,payload)=>{
            state.uesrOrNotUser = payload;
        },
        setAuthorityLabel:(state,payload)=>{
            state.authorityLabel = payload;
        },
        setPaidOrFreeuser: (state,payload)=>{
            state.paidOrFreeUser=payload;
        },
        setTeamType: (state,payload)=>{
            state.teamType=payload;
        }
    }
}