import { removeEvents } from "@/plugins/sessionChecker";
import { store } from "@/store";
import { $hasPermission } from "@/plugins/Helpers";

const Guest = async function (to, from, next) {
    if (!store.state?.auth?.isLoggedIn || to?.name === "set-password") {
        next();
    } else {
        next(process.env.APP_HOMEROUTE ?? "/dashboard");
    }
};

const Auth = async function (to, from, next) {
    const loggedIn = JSON.parse(localStorage.getItem("vuex"));

    if (!loggedIn?.auth?.isLoggedIn) {
        store.dispatch("auth/logout");
    }
    if (store.state?.auth?.isLoggedIn) {
        if (!store.state.auth?.organization && to.name !== "set-organization") {
            return next("/set-organization");
        }
        if (store.state.auth?.organization && to.name === "set-organization") {
            return next("/dashboard");
        }
        if (!$hasPermission.any(to.meta.permission)) {
            return next("/404");
        }
        return next();
    } else {
        axios.defaults.headers.common["Authorization"] = null;
        removeEvents();
        next("/login");
    }
};

const SocialAuth = async function (to, from, next) {
    if (store.state?.auth?.isLoggedIn) {
        if (!store.state.auth?.organization && to.name !== "set-organization") {
            return next("/set-organization");
        }
        if (store.state.auth?.organization && to.name === "set-organization") {
            return next("/dashboard");
        }
        if (!$hasPermission.any(to.meta.permission)) {
            // return next("/404");
        }
        return next();
    } else {
        axios.defaults.headers.common["Authorization"] = null;
        removeEvents();
        next("/login");
    }
};

export { Guest, Auth, SocialAuth };
