import { $notify, getMessageFromError } from "@/plugins/Helpers";

export default {
    namespaced: true,
    state: {
        countries: [],
        states: [],
        cities: [],
        zips: [],
        employmentRoles: [],
        salaryTypes: [],
        peopleTeamsList: [],
        timezones: [],
        currencies: [],
        races: [],
        disabilities: [],
        languages: [],
        educations: [],
        skipOnBoard: false,
        closeBoard: false,
    },
    actions: {
        // country
        fetchCountries: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/locations/countries/all/`
                )
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setCountries", res.results);
                    return res.results;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // state
        fetchStates: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/locations/states/all/`
                )
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setStates", res.results);
                    return res.results;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createState: ({ rootState, commit }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/locations/states/create/`,
                    payload
                )
                .then((res) => {
                    commit("setState", res);
                    $notify.success("State create successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // cities
        fetchCities: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/locations/cities/all/`
                )
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setCities", res.results);
                    return res.results;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createCity: ({ rootState, commit }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/locations/cities/create/`,
                    payload
                )
                .then((res) => {
                    commit("setCity", res);
                    $notify.success("City create successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // zips
        fetchZips: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/locations/zips/all/`
                )
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setZips", res.results);
                    return res.results;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createZip: ({ rootState, commit }, payload) => {
            payload.organization = rootState.auth.organization.organization;
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/locations/zips/create/`,
                    payload
                )
                .then((res) => {
                    commit("setZip", res);
                    $notify.success("Zip create successfully");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // employment roles
        fetchEmploymentRoles: ({ commit }) => {
            return axios
                .get(`/employment-roles/all/`)
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setEmploymentRoles", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // salary types
        fetchSalaryTypes: ({ commit }) => {
            return axios
                .get(`/salary-types/all/`)
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setSalaryTypes", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // people types
        fetchPeopleTeam: ({ commit }) => {
            return axios
                .get(`/salary-types/all/`)
                .then((res) => {
                    res.results.map((item) => {
                        item.label = item.name;
                        item.value = item.id;
                    });
                    commit("setPeopleTeam", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // timezone
        fetchTimezones: ({ commit }) => {
            return axios
                .get(`/organization/timezone/all/`)
                .then((res) => {
                    res.results.map((timezone) => {
                        timezone.label = timezone.text;
                        timezone.value = timezone.id;
                    });
                    commit("setTimezones", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // currency
        fetchCurrencies: ({ commit }) => {
            return axios
                .get(`/organization/currency/all/`)
                .then((res) => {
                    res.results.map((currency) => {
                        currency.label = `${currency.symbol} ${currency.name}`;
                        currency.value = currency.id;
                    });
                    commit("setCurrencies", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        // race
        fetchRaces: ({ commit }) => {
            return axios.get(`races/all/`)
                .then(res => {
                    res.results.map((item) => {
                        item.label = item?.name;
                        item.value = item?.id;
                    })
                    commit("setRaces", res.results)
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
        // disabilities
        fetchDisabilities: ({ rootState, commit }) => {
            return axios.get(`${rootState.auth.organization.organization}/peoples/disabilities/all/`)
                .then(res => {
                    res.results.map((item) => {
                        item.label = item?.name;
                        item.value = item?.id;
                    })
                    commit("setDisabilities", res.results)
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
        // languages
        fetchLanguages: ({ rootState, commit }) => {
            return axios.get(`${rootState.auth.organization.organization}/languages/all/`)
                .then(res => {
                    res.results.map((item) => {
                        item.label = item?.name;
                        item.value = item?.id;
                    })
                    commit("setLanguages", res.results)
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
        // educationi
        fetchEducations: ({ commit }) => {
            return axios.get(`educations/all/`)
                .then(res => {
                    res.results.map((item) => {
                        item.label = item?.name;
                        item.value = item?.id;
                    })
                    commit("setEducations", res.results)
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                })
        },
    },

    mutations: {
        setRaces: (state, payload) => {
            state.races = payload;
        },
        setDisabilities: (state, payload) => {
            state.disabilities = payload;
        },
        setLanguages: (state, payload) => {
            state.languages = payload;
        },
        setEducations: (state, payload) => {
            state.educations = payload;
        },
        // country
        setCountries: (state, payload) => {
            state.countries = payload;
        },
        clearCountries: (state) => {
            state.countries = [];
        },

        // states
        setStates: (state, payload) => {
            state.states = payload;
        },
        setState: (state, payload) => {
            payload = {
                ...payload,
                ...{ label: payload.name, value: payload.id },
            };
            state.states.push(payload);
        },
        clearStates: (state) => {
            state.states = [];
        },

        // cities
        setCities: (state, payload) => {
            state.cities = payload;
        },
        setCity: (state, payload) => {
            payload = {
                ...payload,
                ...{ label: payload.name, value: payload.id },
            };
            state.cities.push(payload);
        },
        clearCities: (state) => {
            state.cities = [];
        },

        // zips
        setZips: (state, payload) => {
            state.zips = payload;
        },
        setZip: (state, payload) => {
            payload = {
                ...payload,
                ...{ label: payload.name, value: payload.id },
            };
            state.zips.push(payload);
        },
        clearZips: (state) => {
            state.zips = [];
        },

        // employment roles
        setEmploymentRoles: (state, payload) => {
            state.employmentRoles = payload;
        },

        // salary types
        setSalaryTypes: (state, payload) => {
            state.salaryTypes = payload;
        },

        // salary types
        setPeopleTeam: (state, payload) => {
            state.peopleTeamsList = payload;
        },

        // time timezone
        setTimezones: (state, payload) => {
            state.timezones = payload;
        },
        clearTimezones: (state) => {
            state.timezones = [];
        },

        // time timezone
        setCurrencies: (state, payload) => {
            state.currencies = payload;
        },
        clearCurrencies: (state) => {
            state.currencies = [];
        },
        setOnBoardSkip: (state)=>{
            state.skipOnBoard = true;
        },
        setOnBoardWrapper: (state)=>{
            state.closeBoard = true;
        },
        reset: (state) => {
            state.countries = [];
            state.states = [];
            state.cities = [];
            state.zips = [];
            state.employmentRoles = [];
            state.salaryTypes = [];
            state.peopleTeamsList = [];
            state.timezones = [];
            state.currencies = [];
            state.skipOnBoard = false;
            state.closeBoard = false;
        },
    },
};
