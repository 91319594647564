const goggleTagScript = document.createElement("script");
goggleTagScript.setAttribute(
    "src",
    "https://www.googletagmanager.com/gtag/js?id=G-1B7FKLY970"
);
document.head.appendChild(goggleTagScript);

window.dataLayer = window.dataLayer || [];

function gtag() {
    window.dataLayer.push(arguments);
}

gtag("js", new Date());

gtag("config", "G-1B7FKLY970");
