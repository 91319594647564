import { $notify, getMessageFromError } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
};

export default {
    namespaced: true,
    state: {
        settings: {},
        programs: [],
        program: {},
        activityTypes: [],
        activityType: {},
    },
    actions: {
        fetchSettings: ({ rootState, commit }) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/program_activities/setting/`
                )
                .then((res) => {
                    commit("setSettings", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        updateSettings: ({ rootState, commit }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/program_activities/setting/update/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Settings updated successfully");
                    commit("setSettings", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchPrograms: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/program_activities/program-service/all/?page=${params.page}&page_size=${params.page_size}`
                )
                .then((res) => {
                    commit("setPrograms", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createProgram: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/program_activities/program-service/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("New program created successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        updateProgram: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/program_activities/program-service/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Program updated successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        deleteProgram: ({ rootState }, id) => {
            return axios
                .delete(
                    `/${rootState.auth.organization.organization}/program_activities/program-service/delete/${id}/`
                )
                .then((res) => {
                    $notify.success("Program deleted successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        fetchActivityTypes: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/program_activities/program-activity-type/all/?page=${params.page}&page_size=${params.page_size}`
                )
                .then((res) => {
                    commit("setActivityTypes", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        createActivityType: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/program_activities/program-activity-type/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Activity type created successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        updateActivityType: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/program_activities/program-activity-type/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    // $notify.success("Activity type updated successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        deleteActivityType: ({ rootState }, id) => {
            return axios
                .delete(
                    `/${rootState.auth.organization.organization}/program_activities/program-activity-type/delete/${id}/`
                )
                .then((res) => {
                    $notify.success("Activity type deleted successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setSettings: (state, payload) => {
            state.settings = payload;
        },
        clearSettings: (state) => {
            state.settings = {};
        },
        setPrograms: (state, payload) => {
            state.programs = payload;
        },
        clearPrograms: (state) => {
            state.programs = [];
        },
        setProgram: (state, payload) => {
            state.program = payload;
        },
        clearProgram: (state) => {
            state.program = {};
        },
        setActivityTypes: (state, payload) => {
            state.activityTypes = payload;
        },
        clearActivityTypes: (state) => {
            state.activityTypes = [];
        },
        setActivityType: (state, payload) => {
            state.activityType = payload;
        },
        clearActivityType: (state) => {
            state.activityType = {};
        },
        reset: (state) => {
            state.settings = {};
            state.programs = [];
            state.program = {};
            state.activityTypes = [];
            state.activityType = {};
        },
    },
};
